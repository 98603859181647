<template>
	<section id="section-apk-detail" class="section-content">

		<table id="apkdetail" cellspacing="0">
			<tbody>
				<tr class="bold">
					<td>
						<div>App</div>
					</td>
					<td>
						
						<span>YoWhatsApp</span>
					</td>
				</tr>
				<tr v-for="(data, i) in apkdetailList" :key="i">
					<td>
						<div v-html="data.name"></div>
					</td>
					<td>
						<div v-html="data.value"></div>
					</td>
				</tr>
				<!-- <tr>
					<td>
						<div>RATING</div>
					</td>
					<td>
						<div v-if="$root.initData && $root.initData.settings">
							<span>{{ $root.initData.settings.score }}</span> ( {{ $root.initData.settings.totalCount }} ratings )
						</div>
						<div v-else>
							<span>3.65</span> ( 46258 ratings )
						</div>
					</td>
				</tr> -->
			</tbody>
		</table>
	</section>
</template>

<script>
export default {
	name: "apkdetail",
	data() {
		return {
			apkdetailList: [],
		};
	},
	props: {
		doc: Object,
	},
	mounted() {
		this.apkdetailList = [
			{
				name: "App size",
				value: "61.02 MB",
			},
			{
				name: "Version",
				value: "V10.10",
				itemprop: "version",
			},
			{
				name: "Developer",
				value: "Fouad Mods",
			},
			{ 
				name: "Price",
				value: "Free",
			},
			{
				name: "Last Update",
				value: "2 days ago",
				itemprop: "update",
			},
		];
	},
	
};
</script>
