<template>
	<section id="section-2" class="section-content">
		<h2 v-html="doc.s2.title_2" id="d4"></h2>

		<p>YoWhatsApp offers a variety of features that go beyond standard messaging. Below is a list of some of its most notable features:</p>

		<!-- <div class="advertisment-item">
			<lazypic :img="doc.s2.adimg_1"></lazypic>
		</div> -->

		<div class="intro-li-row">
			<ul class="list-icons">
				<li v-for="(data, i) in doc.s2.ul" :key="i">
					<i class="fa"></i>
					<strong v-html="data.head"></strong>
					<div class="list-detail" v-html="data.content"></div>
					<div class="list-img" v-if="data.img">
						<lazypic :img="data.img" :alt="data.alt"></lazypic>
					</div>
				</li>
			</ul>
		</div>

		<h2 id="d5">What's New in YoWhatsApp V10.10?</h2>
		<ul class="common-list">
			<li v-for="(data,i) in doc.s2.new" :key="i">
				{{ data }}
			</li>
		</ul>

		<h2 v-html="doc.s2.title_3" id="d6"></h2>

		<p>
			YoWhatsApp is a modified app and cannot be found on the Google Play Store. To install it on your Android device, you'll need to download the APK from a trusted source and manually install it. Follow these steps to install YoWhatsApp:
		</p>

		<ul class="common-list number-list">
			<li>
				Enable "Unknown Sources" in your phone's settings: Go to Settings > Security > Unknown Sources and turn it on.
			</li>
			<li>
				Download the YoWhatsApp APK from a trusted site.
			</li>
			<li>
				Open the downloaded file and follow the on-screen instructions to install it.
			</li>
		</ul>
		<p>
			By following these simple steps, you'll have YoWhatsApp ready to use in no time!
		</p>

		<!-- <div class="advertisment-item adv-2">
			<lazypic :img="doc.s2.adimg_2"></lazypic>
		</div> -->
	</section>
</template>

<script>
import lazypic from "@/components/lazypic.vue";

export default {
	name: 's2',
	components: {
		lazypic,
	},
	props: {
		doc: Object,
	},
	methods: {
		gotochat() {
			window.location.href = 'https://www.whatsplus.site/'
		},
		gotoorg() {
			window.location.href = 'https://www.whatspro.org/'
		},
	},
}
</script>
