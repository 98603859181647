<template>
  <!-- <div class="home  home_pc"> -->
  <div class="domain_content">

    <section id="section-1" class="section-content no-padding">
      <div class="head-row">
        <div class="app-intro">
          <h1 class="section-1-rule" v-html="$global.formatstr(doc.s1.h1, month)"></h1>


          <p>
            Looking for the ultimate messaging app that offers more than just the basics? The latest version of YoWhatsApp is here, enhancing the popular WhatsApp experience with a range of new features and customization options. While its design closely mirrors the original WhatsApp, YoWhatsApp introduces a wide array of enhanced functionalities.
          </p>

          <myimg name="yo-new" alt="yowhatsapp apk" />

        </div>
      </div>

      <category :arr="cate" />

		  <h2 v-html="doc.apkdetail.title_1"></h2>

      <ApkDetail :doc="doc" id="d1"/>

      <DownloadBtn class="top-download fix has-exp" :exp-data="`show_${pageName}home_download`"
        :name="pageName" :from="from" :filename="filename" :isOffical="isOffical" :txt="txt"/>

      <h2 id="d2">Why Choose YoWhatsApp?</h2>
      <p>
        One of the standout advantages of YoWhatsApp is the greater control it provides over privacy and customization settings, making it a popular choice for those seeking more from their messaging app. It has smaller user base like <a class="highlight" href="/">GB WhatsApp</a>. However, it has smaller account banning risks as well.
      </p>


      <h2 id="d3">YoWhatsApp vs WhatsApp</h2>

      <p>YoWhatsApp introduces numerous improvements over the original WhatsApp. From aesthetic themes to privacy features like hiding your online status, YoWhatsApp takes customization to the next level. Here are some key differences:</p>

      <table id="playlist" cellspacing="0">
        <tbody>
          <tr>
            <th style="width:29%">Feature</th>
            <th>YoWhatsApp</th>
            <th>WhatsApp</th>
          </tr>
          <tr v-for="(data, i) in feature" :key="i">
            <td>
              <div v-html="data.feature"></div>
            </td>
            <td>
              <div v-html="data.yo"></div>
            </td>
            <td>
              <div v-html="data.whs"></div>
            </td>
          </tr>
        </tbody>
      </table>
    </section>

    <S2 :doc="doc" />

    <S3 :doc="doc" :pageName="pageName" />

    <div id="snippet-box">
      <div class="snippet-title">Summary</div>

      <div itemscope="" itemtype="https://schema.org/SoftwareApplication">
        <div class="aio-info">
          <div class="snippet-label-img snippet-clear">Author Rating</div>
          <div class="snippet-data-img"><span class="star-img"><img src="@/assets/1star.png"
                alt="1star"><img src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png"
                alt="1star"><img src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png"
                alt="1star"></span></div>
          <div class="snippet-clear"></div>
          <div class="aggregate_sec" itemprop="aggregateRating" itemscope=""
            itemtype="https://schema.org/AggregateRating">
            <div class="snippet-label-img">Aggregate Rating</div>
            <div class="snippet-data-img"><span itemprop="ratingValue">3.65</span> based on <span
                class="rating-count" itemprop="ratingCount">46258</span> votes </div>
          </div>
          <div class="snippet-clear"></div>
          <div class="snippet-label-img">Software Name</div>
          <div class="snippet-data-img"> <span itemprop="name">YOWhatsApp APK</span></div>
          <div class="snippet-clear"></div>
          <div class="snippet-label-img">Operating System</div>
          <div class="snippet-data-img"> <span itemprop="operatingSystem">Android</span></div>
          <div class="snippet-clear"></div>
          <div class="snippet-label-img">Software Category</div>
          <div class="snippet-data-img"> <span itemprop="applicationCategory">App</span></div>
          <div class="snippet-clear"></div>
          <div class="snippet-label-img">Price</div>
          <div class="snippet-data-img"> <span itemprop="offers" itemscope=""
              itemtype="https://schema.org/Offer">
              <span itemprop="priceCurrency">$</span> <span itemprop="price"> 0</span></span></div>
          <div class="snippet-clear"></div>
          <div class="snippet-label-img">Landing Page</div>
          <div class="snippet-data-img"> <a itemprop="featureList"
            href="https://gbapksdownload.com/ ">https://gbapksdownload.com/</a></div>
          <div class="snippet-clear"></div>
        </div>
      </div>
    </div>

    <Rate />

  </div>
</template>

<script>
// @ is an alias to /src
import "@/css/gb/pc.scss";
import DownloadBtn from "@/components/DownloadBtn.vue";
import S2 from "./assets/section2.vue";
import S3 from "./assets/section3.vue";
import ApkDetail from "./assets/apkdetail.vue";
import Rate from "@/components/rate.vue";
import Category from "../../components/category.vue";


export default {
  name: "pc",
  components: {
    DownloadBtn,
    S2,
    S3,
    ApkDetail,
    Rate,
    Category,
  },
  data () {
    return {
      pageName: "yo",
      doc: null,
      myApp: null,
      from: "yo",
      filename: "yo",
      feature: [
        {
            feature: "Airplane Mode",
            yo: '<div class="check-1"></div>',
            whs: '<div class="check-0"></div>',
        },
        {
            feature: "Save Status",
            yo: '<div class="check-1"></div>',
            whs: '<div class="check-0"></div>',
        },
        {
            feature: "Unread Message",
            yo: '<div class="check-1"></div>',
            whs: '<div class="check-0"></div>',
        },
        {
            feature: "Schedule Message",
            yo: '<div class="check-1"></div>',
            whs: '<div class="check-0"></div>',
        },
        {
            feature: "Anti-delete",
            yo: '<div class="check-1"></div>',
            whs: '<div class="check-0"></div>',
        },
        {
            feature: "Auto Reply",
            yo: '<div class="check-1"></div>',
            whs: '<div class="check-0"></div>',
        },
        {
            feature: "Themes",
            yo: 'plenty',
            whs: 'limited',
        },
        {
            feature: "Image Quality",
            yo: 'original',
            whs: 'compressed',
        },
        {
            feature: "App Lock",
            yo: '<div class="check-1"></div>',
            whs: '<div class="check-1"></div>',
        },
        {
            feature: "Freeze Last Seen",
            yo: '<div class="check-1"></div>',
            whs: '<div class="check-1"></div>',
        },
      ],

      cate: [
        "1. Download YoWhatsApp APK",
        "2. Why choose YoWhatsApp?",
        "3. YoWhatsApp vs. WhatsApp",
        "4. Main Features of YoWhatsApp APK",
        "5. What's New in YoWhatsApp V10.10?",
        "6. Installation Guide"
      ],

      viewHeight: null,
      scrollEventFun: null,
      month: "",

      ratingStar: null,
      showCollect: false,
      isOffical: 0,
      txt: "Download YoWhatsApp",
    };
  },
  created () {
    this.myApp = this.$store.state.yo;
    this.doc = this.myApp.doc;
  },
  beforeMount () {
    [this.from, this.filename, this.isOffical] = [
      this.$global.GetQueryString("from") || "",
      this.$global.GetQueryString("filename") || "",
      parseInt(this.$global.GetQueryString("isOffical")) || 0,
    ];
    console.log(`from:${this.from},filename:${this.filename}`);

    this.$store.state.activeName = this.myApp.name;
    this.$store.state.activeLogo = this.myApp.logo;

    const monthArray = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    this.month = monthArray[new Date().getMonth()];

    this.$logEvent(`show_yohome_${this.$route.name}`);
  },
  mounted () {
    let script = document.querySelector("#script");
    if (script) {
      return;
    }

    let recaptchaScript = document.createElement("script");
    recaptchaScript.async = true;
    recaptchaScript.id = "script";
    recaptchaScript.setAttribute(
      "src",
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3240446016388496"
    );
    recaptchaScript.setAttribute("crossorigin", "anonymous");
    document.head.appendChild(recaptchaScript);
    setTimeout(() => {
      this.viewHeight = window.innerHeight;
      this.scrollEvent();
      // 监听scroll事件
      this.scrollEventFun = this.$global.throttle(this.scrollEvent, 300);
      window.addEventListener("scroll", this.scrollEventFun, false);

      if (this.$route.params.scrollTo) {
        document
          .querySelector(`#${this.$route.params.scrollTo}`)
          .scrollIntoView({
            behavior: "smooth",
          });
      }
    }, 1000);

    setTimeout(() => {
      this.showTeleMsg = false;
    }, 2000);
  },
  beforeDestroy () {
    // 防止内存溢出
    window.removeEventListener("scroll", this.scrollEventFun, false);
  },
  methods: {
    getOffsetTop (element) {
      let actualTop = element.offsetTop;
      let current = element.offsetParent;
      while (current !== null) {
        actualTop += current.offsetTop;
        current = current.offsetParent;
      }
      return actualTop;
    },
    // 获取窗口滚动高度
    scrollTop () {
      return (
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset
      );
    },
    scrollEvent () {
      let $scrollTop = this.scrollTop();
      let nodeArr = document.querySelectorAll(".has-exp");

      if ($scrollTop >= 20) {
        this.showTeleMsg = false;
      }
      for (let i = 0; i < nodeArr.length; i++) {
        let domTop =
          this.getOffsetTop(nodeArr[i]) +
          nodeArr[i].getBoundingClientRect().height / 3;
        // 当正文部分露出即发此曝光
        let topHeight = this.getOffsetTop(nodeArr[i]) - $scrollTop;
        // console.log('topHeight', topHeight);
        let bottomHeight = domTop - $scrollTop;
        if (topHeight >= 0 && bottomHeight <= this.viewHeight) {
          if (!nodeArr[i].getAttribute("data-exposure")) {
            nodeArr[i].setAttribute("data-exposure", "1");
            const _this = this;
            new Promise((resolve) => {
              resolve();
            }).then(() => {
              let expData = nodeArr[i].getAttribute("exp-data");
              _this.expPost(expData);
            });
          }
        }
        if (topHeight > this.viewHeight) return false;
      }
    },
    expPost (exposeData) {
      this.$logEvent(exposeData);
      console.log(`曝光统计log ${exposeData}`);
    },
    download () {
      // this.$global.download(this.myApp.apk);
      this.$logEvent("click_gwhome_download");
      this.$router.push({
        name: "download_pc",
        params: { apk: this.myApp.apk },
      });
    },
    downloadad (name, apk) {
      this.$global.download(apk);
      this.$logEvent(`click_gwhome_${name}_download`);
    },
    gotoTele () {
      window.location.href = "https://t.me/gb_download";
    },
    gotochat () {
      window.location.href = "https://www.whatsplus.online/";
    },
  },

  metaInfo () {
    return {
      title: `Download Yo WhatsApp(YOWA) APK New Updated Version on Android Free 2024`,
      script: [
        {
          vmid: "extscript",
          innerHTML: `
					{
						"@context": "https://schema.org",
						"@graph": [
							{
								"@type": "SoftwareApplication",
								"name": "YoWhatsApp APK",
								"operatingSystem": "ANDROID",
								"applicationCategory": "App",
								"aggregateRating": {
									"@type": "AggregateRating",
									"ratingValue": "4.8",
									"bestRating": "5",
									"worstRating": "1",
									"ratingCount": "${Math.floor(
            this.$store.state.yo.clicks +
            4000000 +
            Math.floor(Math.random() * 100) +
            1
          )}",
									"reviewCount": "${Math.floor(
            this.$store.state.yo.clicks +
            20000 +
            Math.floor(Math.random() * 100) +
            1
          )}"
								},
								"offers": {
									"@type": "Offer",
									"price": "0",
									"priceCurrency": "USD"
								}
							},
							{
								"@type": "BreadcrumbList",
								"itemListElement": [
									{ "@type": "ListItem", "position": 1, "name": "YO WhatsApp", "item": "https://gbapksdownload.com/yowhatsapp.html" },
									{ "@type": "ListItem", "position": 2, "name": "YO WhatsApp Download" , "item": "https://gbapksdownload.com/yowhatsapp-apk/" },
									{ "@type": "ListItem", "position": 3, "name": "YOWhatsApp APK Download"  }
								]
							}
							
						]
					}
				`,
          type: "application/ld+json",
        },
      ],
      __dangerouslyDisableSanitizers: ["script"],
      meta: [
        {
          name: "keywords",
          vmid: "keywords",
          content:
            "yowhatsapp, yo whatsapp, yowhatsapp download, yo whatsapp download",
        },
        {
          name: "description",
          vmid: "description",
          content: `YoWhatsApp APK latest version upgraded their anti-ban mechanism. Download the reliable YoWhatsApp APK on Android phone for free. `,
        },
        {
          name: "og:description",
          vmid: "og:description",
          content: `YoWhatsApp APK latest version upgraded their anti-ban mechanism. Download the reliable YoWhatsApp APK on Android phone for free. `,
        },
        {
          name: "og:title",
          vmid: "og:title",
          content: `Download Yo WhatsApp(YOWA) APK New Updated Version on Android Free 2024`,
        },
        {
          name: "image",
          vmid: "image",
          content: `https://cdn.gbwhatsapp.download/img/YoWhatsApp.webp`,
        },
        {
          name: "og:url",
          vmid: "og:url",
          content: window.location.href,
        },
        {
          name: "og:image",
          content: `https://cdn.gbwhatsapp.download/img/YoWhatsApp.webp`,
        },
      ],
      link: [
        {
          rel: "canonical",
          vmid: "canonical",
          href: `https://gbapksdownload.com/yowhatsapp.html`,
        },
      ],
    };
  },
};
</script>
